/** @format */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { FixedSizeList as List } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import Pusher from "pusher-js"
import i18n from "util/i18n"
import _ from "lodash"
import SinglePing from "./SinglePing"
import { getPingFeed, getTotalPingCount } from "services/pingService"
import Loading from "./Loading"
import { PingList, TotalPings, SidePanelWrapper } from "./styledComponents"
import { cfgKeys } from "../../App"
import Cookies from "js-cookie"

const propTypes = {
  onShowMyPings: PropTypes.func,
}

const SidePanel = props => {
  const tpcVal = Cookies.get("SID") + "_tpc"
  const isNewPing = Cookies.get("SID") + "_new"
  const pusher = useRef()
  const channelRef = useRef()
  const [pings, setPings] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const myChkBoolVal = useRef(false)
  const [totalPingCount, setTotalPingCount] = useState(true)
  const myRef = useRef()
  const myImgRef = useRef()
  const myTxtRef = useRef()
  const myChkBoxRef = useRef()
  const itemCount = hasNextPage ? pings.length + 1 : pings.length
  pusher.current = new Pusher(cfgKeys["PSHER"], {
    cluster: "ap2"
  })
  channelRef.current = pusher.current.subscribe("ping")

  const bartanimate = () => {
    if (myRef.current.style.width === "14rem" || myRef.current.style.width === "") {
      myRef.current.style.width = "6.2rem"
      myImgRef.current.src = "/static/media/expand.png"
      myTxtRef.current.style.fontSize = "11px"
      myChkBoxRef.current.style.height = "11px"
    } else {
      myRef.current.style.width = "14rem"
      myImgRef.current.src = "/static/media/collapse.png"
      myTxtRef.current.style.fontSize = "16px"
      myChkBoxRef.current.style.height = "16px"
    }
  }
  const loadPingFeedNCounter = (chked, startIndex, stopIndex) => {
    getTotalPingCount(chked).then(data => {
      setTotalPingCount(data.totalPingCount)
    })

    getPingFeed(chked, startIndex, stopIndex).then(data => {
      const { rtlivefeed = { pings: [] } } = data || {}
      setHasNextPage(rtlivefeed.hasNext)
      setPings(p => _.orderBy(_.uniqBy(p.concat(rtlivefeed.pings), "pingts"), ["pingts"], ["desc"]))
    })
  }

  const handleChange = () => {
    myChkBoolVal.current = !isChecked
    setIsChecked(!isChecked)
    setPings([])
    props.onShowMyPings(!isChecked)
    loadPingFeedNCounter(!isChecked, 0, 20)
  }

  useEffect(() => {
    channelRef.current.bind("new", ({ data }) => {
      if (myChkBoolVal.current) {
        if (data.hasOwnProperty(isNewPing)) {
          setPings(p => [data.ping].concat(p))
        }
        else {
          setPings(p => _.orderBy(_.uniqBy(p.concat(pings), "pingts"), ["pingts"], ["desc"]))
        }
      }
      else {
        setPings(p => [data.ping].concat(p))
      }
    })
    return () => {
      channelRef.current.unbind("new")
      pusher.current.unsubscribe("ping")
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    channelRef.current.bind("totalCount", ({ data }) => {
      if (myChkBoolVal.current) {
        if (data.hasOwnProperty(tpcVal)) {
          setTotalPingCount(data[tpcVal])
        }
        else {
          setTotalPingCount(Cookies.get(tpcVal))
        }
      }
      else {
        setTotalPingCount(data.tpc)
      }
    })
    return () => {
      channelRef.current.unbind("totalCount")
      pusher.current.unsubscribe("ping")
    }

    // eslint-disable-next-line
  }, [])

  const loadNextPage = (startIndex, stopIndex) => {
    loadPingFeedNCounter(isChecked, startIndex, stopIndex)
  }

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < pings.length

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content
    if (!isItemLoaded(index)) {
      content = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Loading />
        </div>
      )
    } else {
      content = <SinglePing ping={pings[index]} />
    }
    return <div style={style}>{content}</div>
  }

  return (
    <SidePanelWrapper ref={myRef}>
      <div>
        <span>
          <img ref={myImgRef} src="/static/media/collapse.png" onClick={bartanimate} />
        </span>
      </div>
      <div>
        <span ref={myTxtRef}>
          <input id="shwMyPngsChkBx" type="checkbox" ref={myChkBoxRef} onChange={handleChange} />{" "}
          {i18n.t("Labels.loadMyPings")}
        </span>
      </div>
      <TotalPings>
        <span>{i18n.t("Labels.totalPings")}</span>
        <span id='tpc'>{totalPingCount === undefined ? <Loading /> : totalPingCount.toLocaleString()}</span>
      </TotalPings>
      <PingList>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadNextPage}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  itemCount={itemCount}
                  itemSize={100}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {Item}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </PingList>
    </SidePanelWrapper>
  )
}

SidePanel.propTypes = propTypes

SidePanel.displayName = "SidePanel"

export default SidePanel
