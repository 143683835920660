/** @format */

import React from "react"

import GlobalStyle from "util/GlobalStyle"
import NavBar from "components/NavBar"
import {
  AppWrapper,
  AboutWrapper,
  Atma,
  Hero,
  Main,
  WelcomeText,
  HeaderText,
  BodyText,
  Row,
  Column,
  SmallHeaderText
} from "./styledComponents"
import { WorldHero, PeopleHero, PingHero } from "images"

import i18n from "util/i18n"

const fb = "https://www.facebook.com/Atmaearth/"
const twitter = "https://www.twitter.com/Atmaearth/"
const insta = "https://www.instagram.com/atmaearth/"
const email = "contact@pingcolors.com"

function About(props) {
  return (
    <AppWrapper>
      <GlobalStyle />
      <NavBar
        activePings={props.activePings}
        userLocation={props.userLocation}
        loggedIn={true}
        onLogout={() => props.setLoggedIn(false)}
        hideLinks
        userEmail={"Test@gmail.com"}
        onPingCreate={props.onPingCreate}
      />
      <Main>
        <Atma>Atma: {i18n.t("Labels.geoconsciousNetwork")}</Atma>
        <AboutWrapper>
          <Hero>
            <img src={WorldHero} alt={i18n.t("Labels.worldHero")} />
            <div />
          </Hero>
          <WelcomeText>
            <p>{i18n.t("About.welcomeText.1")}</p>
            <p>{i18n.t("About.welcomeText.2")}</p>
          </WelcomeText>
          <Row>
            <Column>
              <HeaderText>{i18n.t("About.header1")}</HeaderText>
              <BodyText>
                <p>{i18n.t("About.body1.1")}</p>
                <p>{i18n.t("About.body1.2")}</p>
                <p>{i18n.t("About.body1.3")}</p>
              </BodyText>
              <HeaderText>{i18n.t("About.header2")}</HeaderText>
              <BodyText>
                <p>{i18n.t("About.body2")}</p>
              </BodyText>
            </Column>
            <Column>
              <img src={PeopleHero} alt={i18n.t("Labels.peopleCreatingPings")} />
            </Column>
          </Row>
          <Row>
            <Column>
              <HeaderText>{i18n.t("About.header3")}</HeaderText>
              <BodyText>
                <p>{i18n.t("About.body3.1")}</p>
                <p>{i18n.t("About.body3.2")}</p>
                <p>{i18n.t("About.body3.3")}</p>
                <p>{i18n.t("About.body3.4")}</p>
              </BodyText>
            </Column>
            <Column id="leaf">
              <img src={PingHero} alt={i18n.t("Labels.pingLeaves")} />
            </Column>
          </Row>
          <BodyText id="example-uses">
            <p>{i18n.t("About.exampleUses")}</p>
            <ul>
              <li>{i18n.t("About.examples.1")}</li>
              <li>{i18n.t("About.examples.2")}</li>
              <li>{i18n.t("About.examples.3")}</li>
            </ul>
            <p>{i18n.t("About.body3.5")}</p>
          </BodyText>
          <HeaderText>{i18n.t("About.faqs.label")}</HeaderText>
          <div id="faq-section">
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question1")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer1")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question2")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer2")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question3")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer3")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question4")}</SmallHeaderText>
              <BodyText>
                <p>{i18n.t("About.faqs.answer4.1")}</p>
                <p>{i18n.t("About.faqs.answer4.2")}</p>
                <p>{i18n.t("About.faqs.answer4.3")}</p>
                <p>{i18n.t("About.faqs.answer4.4")}</p>
              </BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question5")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer5")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question6")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer6")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question7")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer7")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question8")}</SmallHeaderText>
              <BodyText>
                <p>{i18n.t("About.faqs.answer8.1")}</p>
                <p>{i18n.t("About.faqs.answer8.2")}</p>
              </BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question9")}</SmallHeaderText>
              <BodyText>
                <p>{i18n.t("About.faqs.answer9.main")}</p>
                <ul>
                  <li>{i18n.t("About.faqs.answer9.1")}</li>
                  <li>{i18n.t("About.faqs.answer9.2")}</li>
                  <li>{i18n.t("About.faqs.answer9.3")}</li>
                  <li>{i18n.t("About.faqs.answer9.4")}</li>
                </ul>
              </BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question10")}</SmallHeaderText>
              <BodyText>{i18n.t("About.faqs.answer10")}</BodyText>
            </Column>
            <Column center>
              <SmallHeaderText>{i18n.t("About.faqs.question11")}</SmallHeaderText>
              <BodyText>
                <p>
                  <a href={fb}>{i18n.t("About.faqs.links.facebook")}</a>
                </p>
                <p>
                  <a href={twitter}>{i18n.t("About.faqs.links.twitter")}</a>
                </p>
                <p>
                  <a href={insta}>{i18n.t("About.faqs.links.instagram")}</a>
                </p>
                <p>
                  <a href={`mailto:${email}`}>{i18n.t("About.faqs.links.email")}</a>
                </p>
              </BodyText>
            </Column>
          </div>
        </AboutWrapper>
      </Main>
    </AppWrapper>
  )
}

export default About
