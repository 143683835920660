/** @format */

import mapboxgl from "mapbox-gl"
import ReactDOM from "react-dom"
import { PingLeaf } from "components"
import {
  CIRCLE_OPTIONS,
  CIRCLE_TO_SPIRAL_SWITCHOVER,
  MAX_LEAVES_TO_SPIDERIFY,
  SPIDER_LEAVES_LAYER_NAME,
  SPIDER_LEGS,
  SPIDER_LEGS_LAYER_NAME,
  SPIDER_LEGS_PAINT_OPTION,
  SPIDER_TYPE,
  SPIRAL_OPTIONS
} from "./constants"

import { orderBy } from "lodash"

const _removeSourceAndLayer = (map, id) => {
  map.getLayer(id) && map.removeLayer(id)
  map.getSource(id) && map.removeSource(id)
}

const clearSpiderifiedCluster = map => {
  if (!map) return
  _removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME)
  _removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME)
}

const _generateEquidistantPointsInCircle = ({ totalPoints = 1 }) => {
  let points = []
  let theta = (Math.PI * 2) / totalPoints
  let angle = theta
  for (let i = 0; i < totalPoints; i++) {
    angle = theta * i
    points.push({
      x: CIRCLE_OPTIONS.distanceBetweenPoints * Math.cos(angle),
      y: CIRCLE_OPTIONS.distanceBetweenPoints * Math.sin(angle)
    })
  }
  return points
}

const _generateEquidistantPointsInSpiral = ({ totalPoints = 10 }) => {
  let points = [{ x: 0, y: 0 }]
  // Higher modifier = closer spiral lines
  const rotations = totalPoints * SPIRAL_OPTIONS.rotationsModifier
  const distanceBetweenPoints = SPIRAL_OPTIONS.distanceBetweenPoints
  const radius = totalPoints * SPIRAL_OPTIONS.radiusModifier
  // Value of theta corresponding to end of last coil
  const thetaMax = rotations * 2 * Math.PI
  // How far to step away from center for each side.
  const awayStep = radius / thetaMax
  for (
    let theta = distanceBetweenPoints / awayStep;
    points.length <= totalPoints + SPIRAL_OPTIONS.lengthModifier;

  ) {
    points.push({
      x: Math.cos(theta) * (awayStep * theta),
      y: Math.sin(theta) * (awayStep * theta)
    })
    theta += distanceBetweenPoints / (awayStep * theta)
  }
  return points.slice(0, totalPoints)
}

const _generateLeavesCoordinates = ({ leafCount }) => {
  // Position cluster's leaves in circle if below threshold, spiral otherwise
  let points
  if (leafCount < CIRCLE_TO_SPIRAL_SWITCHOVER) {
    points = _generateEquidistantPointsInCircle({
      totalPoints: leafCount
    })
  } else {
    points = _generateEquidistantPointsInSpiral({
      totalPoints: leafCount
    })
  }
  return points
}

const spiderifyCluster = ({ map, source, clusterToSpiderify }, popupRegisterCallback) => {
  let spiderlegsCollection = []
  let spiderLeavesCollection = []

  const onGetClusterLeaves = (error, features) => {
    if (error) {
      console.warning("Cluster does not exists at this zoom")
      return
    }

    let leavesCoordinates = _generateLeavesCoordinates({ leafCount: features.length })

    let clusterXY = map.project(clusterToSpiderify.coordinates)

    // Generate spiderlegs and leaves coordinates
    // TODO: use orderby instead of reversing
    orderBy(features, [f => f.properties.pingts], ["desc"]).forEach((element, index) => {
      let spiderLeafLatLng = map.unproject([
        clusterXY.x + leavesCoordinates[index].x,
        clusterXY.y + leavesCoordinates[index].y
      ])
      if (SPIDER_TYPE.toLowerCase() === "layer") {
        spiderLeavesCollection.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
          },
          properties: element.properties
        })
      }

      if (SPIDER_LEGS) {
        spiderlegsCollection.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              clusterToSpiderify.coordinates,
              [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
            ]
          }
        })
      }
    })

    // Draw spiderlegs and leaves coordinates
    if (SPIDER_LEGS) {
      map.addLayer(
        {
          id: SPIDER_LEGS_LAYER_NAME,
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: spiderlegsCollection
            }
          },
          paint: SPIDER_LEGS_PAINT_OPTION
        },
        "clusters"
      )
    }
    if (SPIDER_TYPE.toLowerCase() === "layer") {
      map.addLayer({
        id: SPIDER_LEAVES_LAYER_NAME,
        type: "symbol",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: spiderLeavesCollection
          }
        },
        layout: {
          "icon-image": ["get", ["string", "iconUrl"]],
          "icon-size": 0.25,
          "icon-allow-overlap": true
        }
      })
      map.on("click", SPIDER_LEAVES_LAYER_NAME, function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice()

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        const placeholder = document.createElement("div")
        ReactDOM.render(<PingLeaf pingInfo={e.features[0].properties} />, placeholder)
        const popup = new mapboxgl.Popup({
          className: "ping-leaf-popup",
          closeButton: false,
          closeOnMove: true
        })
          .setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(map)
        popupRegisterCallback(popup)
      })
    }
  }

  map
    .getSource(source)
    .getClusterLeaves(clusterToSpiderify.id, MAX_LEAVES_TO_SPIDERIFY, 0, onGetClusterLeaves)
}

export { spiderifyCluster, clearSpiderifiedCluster }
