/** @format */

const translations = {
  translation: {
    About: {
      welcomeText: {
        1: "Atma is a new social network that reflects thinking and feeling of humanity on a visual, geo-located map where the whole Earth unites as ONE.",
        2: "The goal of Atma is to raise the vibration of the planet and transform it for the betterment of all conscious beings. On a personal level, Atma will help users understand their inner-workings, beliefs, and patterns, encouraging mindfulness and awareness."
      },
      header1: "How does Atma work?",
      header2: "How do you create and observe a Ping?",
      header3: "Why would you Ping?",
      body1: {
        1: "The building blocks of Atma are Pings. A Ping is a single unit of internet consciousness, a snapshot of our human experience visually represented by a clickable button.",
        2: "One Ping is one expression, one thought, one emotion, one feeling, one action, or any form of consciousness.",
        3: "A collection of all Pings makes Atma."
      },
      body2:
        "Press the Create button to select one Ping that represents what you would like to express, and observe trends and patterns. For example, when you select “Sad”, you could observe how often you ping “Sad”, how many other users are “Sad” in your city, and what you can do to alleviate your sadness.",
      body3: {
        1: "There are many reasons to Ping.",
        2: "Understand yourself, develop mindfulness, increase emotional intelligence, change habits, know that you are not alone, improve your well-being, and awaken your soul!",
        3: "Equip yourself with knowledge of how people around the world are feeling at any given moment, build global compassion, solidarity, and collective action.",
        4: "You can truly express yourself as you retain full ownership of all your data and all Pings are anonymous.",
        5: "There’s plenty of focus these days on the Internet being the collective human mind; Atma goes beyond to be the collective human heart and soul."
      },
      exampleUses: "Example uses of a Ping:",
      examples: {
        1: "Create a Ping for yourself -  “Thanks” for your regular gratitude journaling.",
        2: "Create a Ping for the collective - “Change” for better healthcare facilities in your city.",
        3: "Create a Ping for your family - “Healthy” for being safe during the pandemic."
      },
      faqs: {
        label: "FAQS",
        question1: "What does Atma mean?",
        question2: "How do I start?",
        question3: "What is the technical definition of Ping?",
        question4: "What are the types of Pings?",
        question5: "Do I need an account or login to use Atma?",
        question6: "Are you showing the approximate or accurate location?",
        question7: "Are Pings anonymous?",
        question8: "How can I participate in the project?",
        question9: "What comes next?",
        question10: "Where is the Atma project based?",
        question11: "How can we be in touch?",
        answer1: "Atma means “soul” in Sanskrit.",
        answer2:
          "It's simple! Click on the big + button up top to see a series of Pings in the form of colorful, clickable buttons. Choose the one that best represents what you’d like to express.",
        answer3:
          "A Ping is a multi-dimensional, non-divisible, quantum expression used to measure a single human emotion, thought, vibration, or expression of consciousness across the internet.",
        answer4: {
          1: "Circle - Universal Pings.",
          2: "Stars - Emotion Pings.",
          3: "Triangle - Action Pings (coming soon).",
          4: "Square - Thought Pings (coming soon)."
        },
        answer5:
          "No. You can use Atma without a login or account. But, in the future, you can create and store your Pings on your personal account, “Persona”, that you will have full ownership of.",
        answer6:
          "In our first incarnation, we are using your approximate location; in the future, you will have the option of choosing either your approximate or accurate location.",
        answer7:
          "Yes. All Pings remain anonymous. In the future versions, users can choose to switch from anonymous to public pings if they decide to reveal their identity.",
        answer8: {
          1: "You can be our first user by simply creating Pings. You can become a power user by giving us feedback on new types of Pings you would like to see and features you would like to see built.",
          2: "You can also contact us for job openings and of course support us financially."
        },
        answer9: {
          main: "In the next iteration of the prototype, the following features will be released:",
          1: "Persona: Account where you store and record all your Pings.",
          2: "Ping leaf: Ability to add descriptions to the Ping and see more trends and patterns.",
          3: "Information visualization of global Ping data.",
          4: "Native mobile and tablet application."
        },
        answer10:
          "We have employees working globally from New York, Berlin, Arizona, California, and India.",
        links: {
          facebook: "Facebook",
          twitter: "Twitter",
          instagram: "Instagram",
          email: "Email"
        }
      }
    },
    Labels: {
      loadMyPings: "See My Pings",
      approximateLocation: "Approximate location",
      geoconsciousNetwork: "The Geo-Conscious Network",
      peopleCreatingPings: "People with ping icons",
      ping: "ping",
      pingCreated: "Ping created!",
      pingLeaves: "People with pings",
      totalPings: "Total Pings",
      worldHero: "Hero image of the world"
    },
    Nav: {
      aboutUs: "About",
      createNewPing: "Create a new Ping",
      info: "About",
      login: "Login",
      logout: "Logout",
      map: "Map",
      persona: "Persona",
      hello: "Hello",
      ping: "Ping",
      stats: "Stats",
      welcome: "Welcome",
      welcomeMessage: "Select a ping to express yourself"
    },
    PingType: {
      active: "Active",
      angry: "Angry",
      awake: "Awake",
      belief: "Belief",
      bliss: "Bliss",
      bored: "Bored",
      change: "Change",
      depressed: "Depressed",
      dream: "Dream",
      dreams: "Dreams",
      excited: "Excited",
      fear: "Fear",
      forgiveness: "Forgiveness",
      frustrated: "Frustrated",
      goal: "Goal",
      happy: "Happy",
      healthy: "Healthy",
      hope: "Hope",
      idea: "Idea",
      love: "Love",
      ok: "Ok",
      opinion: "Opinion",
      optimistic: "Optimistic",
      pessimistic: "Pessimistic",
      prayer: "Prayer",
      proud: "Proud",
      relaxed: "Relaxed",
      sad: "Sad",
      shameful: "Shameful",
      sick: "Sick",
      sorry: "Sorry",
      thanks: "Thanks",
      tired: "Tired",
      wow: "Wow",
      b: "B",
      f: "F",
      c: "C",
      d: "D",
      e: "E",
      a: "A",
      g: "G",
      h: "H"
    }
  }
}

export default translations
