/** @format */

import React, { useEffect, useState } from "react"
import { createPing, getLocation, getCfgInfo } from "services/pingService"
import { Router, navigate } from "@reach/router"
import { About, Earth } from "components"

export const cfgKeys = getCfgInfo()
export const activePingsList = cfgKeys['ACTIVEPINGS']

const PingCreateOnLoad = ({ userLocation, onPingCreate, type, pid }) => {
  useEffect(() => {
    if (!userLocation) return
    createPing(pid, userLocation)
      .then(ping => onPingCreate(ping))
      .catch(() => window.location.assign("/"))
    //eslint-disable-next-line
  }, [userLocation])
  return null
}

function App() {
  const [loggedIn, setLoggedIn] = useState(true)
  const [createdPing, setCreatedPing] = useState(undefined)
  const [userLocation, setUserLocation] = useState(undefined)
  const [isHTML5GeoLocationFailed, setIsHTML5GeoLocationFailed] = useState(undefined)
  const handlePingCreate = ping => {
    setCreatedPing(ping)
  }

  useEffect(() => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        position => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords
          // update the value of userlocation variable
          setUserLocation({ lat: latitude, lng: longitude })
        },
        // if there was an error getting the users location
        error => {
          console.error("Error getting user location:", error)
          setIsHTML5GeoLocationFailed(true)
        }
      )
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.")
      setIsHTML5GeoLocationFailed(true)
    }
  }, [])
  useEffect(() => {
    if (isHTML5GeoLocationFailed) {
      getLocation().then(({ lat, lng }) => {
        setUserLocation({ lat, lng })
      })
    }
  }, [isHTML5GeoLocationFailed])
  useEffect(() => {
    if (!!createdPing) {
      navigate("/")
    }
  }, [createdPing])
  return (
    <Router>
      <Earth
        activePings={activePingsList}
        exact
        loggedIn={loggedIn}
        path="/"
        setLoggedIn={setLoggedIn}
        showPing={createdPing}
        userLocation={userLocation}
      />
      {activePingsList.map(pType => {
        return (
          <PingCreateOnLoad
            key={pType.type}
            userLocation={userLocation}
            path={`/${pType.type}`}
            onPingCreate={handlePingCreate}
            type={pType.type}
            pid={pType.id}
          />
        )
      })}
      <About
        activePings={activePingsList}
        loggedIn={loggedIn}
        onPingCreate={handlePingCreate}
        path="/about"
        setLoggedIn={setLoggedIn}
        userLocation={userLocation}
      />
    </Router>
  )
}

export default App
