/** @format */

const getBaseUrl = (isApi = true) => {
  const { protocol, hostname, port } = window.location
  const apiSuffix = isApi ? "/api/v1" : ""
  const host = _isLocalHost() && isApi ? "localhost:3001" : hostname
  const portString = _isLocalHost() && isApi ? "" : `:${port}`
  return `${protocol}//${host}${portString}${apiSuffix}`
}

const _isLocalHost = () => {
  const hostname = window.location.hostname
  return hostname.match(/local/) !== null
}

const statusCheck = async response => {
  if (!response.ok) {
    const errorInfo = await response.json()
    const { status, statusText } = response
    return Promise.reject({ status, statusText, errorInfo })
  } else {
    return response.json()
  }
}

const getCfgInfo = () => {
  const req = new XMLHttpRequest()
  req.open("GET", `${getBaseUrl()}/pings/cfg`, false)
  req.send(null)
  if (req.status === 200) {
    return JSON.parse(req.responseText)
  }
  return null
}

const createPing = (pingTypeId, userLocation) => {
  const { lat, lng } = userLocation
  return fetch(`${getBaseUrl()}/pings/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      pingTypeId: pingTypeId,
      latlon: { lat: Number(lat), lon: Number(lng) }
    })
  })
    .then(statusCheck)
    .catch(() => { })
}

// isUser, if the user is logged in and the my pings checkbox is toggled, gets the user's pings
const getPings = (swlat, swlon, nelat, nelon, isUser) => {
  let remoteUrl = "show_pings"
  if (isUser) {
    remoteUrl = "my_show_pings"
  }
  return fetch(`${getBaseUrl()}/map/` + remoteUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      boundedBox: {
        southWestPoint: { lat: Number(swlat), lon: Number(swlon) },
        northEastPoint: { lat: Number(nelat), lon: Number(nelon) }
      }
    })
  })
    .then(statusCheck)
    .catch(() => { })
}

const getPingFeed = (isUser, strtIdx, stpIdx) => {
  let remoteUrl = "livefeed"
  if (isUser) {
    remoteUrl = "my_livefeed"
  }

  return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ startIndex: strtIdx, stopIndex: stpIdx })
  })
    .then(statusCheck)
    .catch(() => { })
}

const isLoggedIn = () => {
  return fetch(`${getBaseUrl()}/users/isloggedin`)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => { })
}
const getTotalPingCount = isUser => {
  let remoteUrl = "total_ping_count"
  if (isUser) {
    remoteUrl = "my_total_ping_count"
  }

  return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => { })
}

const getLocation = () => {
  return fetch(`${getBaseUrl()}/users/me`)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => { })
}

export {
  createPing,
  getCfgInfo,
  getBaseUrl,
  getLocation,
  getPingFeed,
  getPings,
  getTotalPingCount,
  isLoggedIn
}
