/** @format */

import PropTypes from "prop-types"
import React, { useEffect, useRef, useImperativeHandle, useState } from "react"
import { Link } from "@reach/router"
import PingSelector from "./PingSelector"
import { CreatePingImage } from "images"
import { createPing } from "services/pingService"
//import { preloadImages } from "images/pings"
import i18n from "util/i18n"
import { CreatePingButton, LogoText, StyledNavBar, LoginLogoutBtnDiv } from "./styledComponents"

const propTypes = {
  activePings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      category: PropTypes.string
    })
  ),
  userEmail: PropTypes.string,
  loggedIn: PropTypes.bool,
  onLogout: PropTypes.func,
  onPingCreate: PropTypes.func.isRequired,
  hideLinks: PropTypes.bool.isRequired
}

const defaultProps = {
  activePings: [],
  onLogout: () => { }
}

const NavBar = React.forwardRef((props, ref) => {
  const [showPingSelector, setShowPingSelector] = useState(false)
  const [oldGpsCoords, setOldGpsCoords] = useState({ lat: 0.0, lng: 0.0 })
  const selectorRef = useRef()
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  useImperativeHandle(ref, () => ({
    closeSelector: () => setShowPingSelector(false)
  }))

  const handleCreatePingClick = e => {
    e.stopPropagation()
    //close all pingleaf dialogs
    setShowPingSelector(true)
  }
  const infoBtn = (
    <Link to="/about" id="about">
      <span>{i18n.t("Nav.aboutUs")}</span>
    </Link>
  )
  const loginLogoutBtn = (
    <LoginLogoutBtnDiv>
      {props.loggedIn ? (
        <a href="/logout">
          <span>
            {" "}
            {i18n.t("Nav.hello")} {props.userEmail}{" "}
          </span>
        </a>
      ) : (
        <a href="/login">
          <span>{i18n.t("Nav.login")}</span>
        </a>
      )}
      {infoBtn}
    </LoginLogoutBtnDiv>
  )
  const createBtn = (
    <CreatePingButton onClick={handleCreatePingClick}>
      <img src={CreatePingImage} alt={i18n.t("Nav.createNewPing")} />
    </CreatePingButton>
  )
  const handlePingTypeSelect = type => {

    function success(pos) {
      const crd = pos.coords;
      createPing(type, { lat: crd.latitude, lng: crd.longitude }).then(data => {
        props.onPingCreate(data)
        setOldGpsCoords({ lat: crd.latitude, lng: crd.longitude })
        setShowPingSelector(false)
      })
    }
    navigator.geolocation.getCurrentPosition(success, error, options);

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      createPing(type, oldGpsCoords).then(data => {
        props.onPingCreate(data)
        setShowPingSelector(false)
      })
    }

  }
  const handleNavClick = e => {
    if (selectorRef?.current?.contains(e.target)) return
    setShowPingSelector(false)
  }
  return (
    <StyledNavBar hideLinks={props.hideLinks} onClick={handleNavClick}>
      <Link to="/" id="logo">
        <LogoText>{"Atma"}</LogoText>
      </Link>
      {createBtn}
      {loginLogoutBtn}

      {showPingSelector && (
        <PingSelector
          ref={selectorRef}
          activePings={props.activePings}
          onPingTypeSelect={handlePingTypeSelect}
        />
      )}
    </StyledNavBar>
  )
})

NavBar.propTypes = propTypes
NavBar.defaultProps = defaultProps
NavBar.displayName = "NavBar"

export default NavBar
