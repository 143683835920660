/** @format */

import React, { useCallback, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import mapboxgl from "mapbox-gl"
import { AnimateSharedLayout, motion } from "framer-motion"
import GlobalStyle from "util/GlobalStyle"
import { debounce, startCase } from "lodash"
import { NavBar, PingLeaf, SidePanel } from "components"
import { getBaseUrl, getPings, isLoggedIn } from "services/pingService"
import { activePingsList, cfgKeys } from "../../App"
import i18n from "util/i18n"
import Pusher from "pusher-js"
import { spiderifyCluster, clearSpiderifiedCluster } from "./cluster"
import { SPIDER_LEAVES_LAYER_NAME_PREFIX } from "./constants"
import ReactGA from "react-ga4"
import {
  Main,
  AppWrapper,
  LeafWrapper,
  RecentPingDisplay,
  PingCreatedSection,
} from "./styledComponents"

const propTypes = {
  activePings: PropTypes.arrayOf(
    PropTypes.shape({
      pingid: PropTypes.string,
      type: PropTypes.string,
      category: PropTypes.string
    })
  ),
  userLocation: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string
  }),
  loggedIn: PropTypes.bool,
  setLoggedIn: PropTypes.func,
  showPing: PropTypes.object
}

const defaultProps = {
  setLoggedIn: () => { }
}

function Earth(props) {
  const pusher = useRef()
  const channelRef = useRef()
  const navRef = useRef()
  const popupRef = useRef()
  const mapBoxRef = useRef()
  const userEmaill = useRef()
  const userId = useRef()
  const mapContainer = useRef()
  const myPings = useRef(false)
  const isLoading = useRef(true)
  const userLoggedIn = useRef(false)
  const showPingOnLoad = useRef(props.showPing)
  const [pingAggregates, setPingAggregates] = useState({})
  const [currentPing, setCurrentPing] = useState(props.showPing)
  const [imagesLeftToLoad, setImagesLeftToLoad] = useState(activePingsList.length)
  const [isNewPing, setIsNewPing] = useState(false)
  useEffect(() => {
    ReactGA.initialize(cfgKeys["GA"])
    mapboxgl.accessToken = cfgKeys["MAPBX"]
  })

  ReactGA.send({
    page: "Home",
    title: "Ping Home Page",
    hitType: "pageview"
  })
  // eslint-disable-next-line
  const [mapBoxState, setMapBoxState] = useState({
    lat: props.userLocation?.lat ?? 0,
    lng: props.userLocation?.lng ?? 0,
    zoom: 3
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadPings = useCallback(
    debounce((southEastBounds, northWestBounds) => {
      // these directions are not used for now and we fetch everything on the map in pingService
      getPings(
        southEastBounds.lat,
        southEastBounds.lng,
        northWestBounds.lat,
        northWestBounds.lng,
        myPings.current
      )
        .then(data => {
          setPingAggregates(data)
        })
        .catch(error => {
          // TODO: Error Handling
        })
    }, 500),
    []
  )

  useEffect(() => {
    const { lat, lng } = props.userLocation || {}
    if (!lat || !lng) return
    setMapBoxState({
      lat,
      lng
    })
  }, [props.userLocation])
  // map event
  const onMapMove = () => {
    if (!mapBoxRef.current) return
    const bounds = mapBoxRef.current.getBounds()
    const southEastBounds = bounds.getSouthEast().wrap()
    const northWestBounds = bounds.getNorthWest().wrap()
    loadPings(southEastBounds, northWestBounds)
  }
  // map event
  const onLoad = () => {
    isLoggedIn()
      .then(data => {
        if (data !== undefined) {
          userLoggedIn.current = data.status
          userEmaill.current = data.useremail
          userId.current = data.userId
        } else {
          userLoggedIn.current = false
          userEmaill.current = ""
        }
      })
      .catch(error => {
        console.log("got error at loggedin")
      })

    isLoading.current = false
    // load/add the pingstypes into the map, this is async in nature
    activePingsList.forEach(p => {
      const imgUrl = `${getBaseUrl(false)}${p.img}`
      mapBoxRef.current.loadImage(imgUrl, (error, image) => {
        if (error) throw error
        setImagesLeftToLoad(draft => draft - 1)
        mapBoxRef.current.addImage(p.type.toLowerCase(), image)
      })
    })
    if (Object.keys(pingAggregates).length === 0) {
      onMapMove()
    }
    // The following are for different on click handlers
    // 1) click on cluster, which has pings grouped
    mapBoxRef.current
      .on("click", "clusters", e => {
        const featuresAtPoint = mapBoxRef.current.queryRenderedFeatures(e.point)
        const hasLeaf =
          featuresAtPoint.filter(feature =>
            feature.layer.id.startsWith(SPIDER_LEAVES_LAYER_NAME_PREFIX)
          ).length > 0
        if (hasLeaf) return
        const features = featuresAtPoint.filter(feature => feature.layer.id === "clusters")
        let clusterId = features[0].properties.cluster_id

        // Zoom on cluster or spiderify it
        if (mapBoxRef.current.getZoom() < 12) {
          mapBoxRef.current.getSource("pings").getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return
            mapBoxRef.current.jumpTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            })
          })
        } else {
          spiderifyCluster(
            {
              map: mapBoxRef.current,
              source: "pings",
              clusterToSpiderify: {
                id: clusterId,
                coordinates: features[0].geometry.coordinates
              }
            },
            popup => (popupRef.current = popup)
          )
        }
      })
      .on("click", e => {
        const features = mapBoxRef.current.queryRenderedFeatures(e.point)
        let isOverLeaf = false
        features.forEach(feature => {
          const { layer } = feature
          isOverLeaf = isOverLeaf || layer.id.startsWith(SPIDER_LEAVES_LAYER_NAME_PREFIX)
        })
        navRef.current.closeSelector()
        if (!isOverLeaf && popupRef.current && !popupRef.current.isOpen())
          clearSpiderifiedCluster(mapBoxRef.current)
      })
      .on("zoomstart", () => {
        clearSpiderifiedCluster(mapBoxRef.current)
      })
      .on("click", "unclustered-point", function (e) {
        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        var coordinates = e.features[0].geometry.coordinates.slice()

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        const placeholder = document.createElement("div")
        ReactDOM.render(<PingLeaf pingInfo={e.features[0].properties} />, placeholder)
        popupRef.current = new mapboxgl.Popup({
          className: "ping-leaf-popup",
          closeButton: false,
          closeOnMove: true
        })
          .setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(mapBoxRef.current)
      })
      .on("mouseenter", "clusters", function () {
        mapBoxRef.current.getCanvas().style.cursor = "pointer"
      })
      .on("mouseleave", "clusters", function () {
        mapBoxRef.current.getCanvas().style.cursor = ""
      })
  }
  useEffect(() => {
    if (!mapBoxRef.current) return
    let { lat, lng } = currentPing || {}
    lat = lat || mapBoxState.lat
    lng = lng || mapBoxState.lng
    const zoom = currentPing ? 14 : mapBoxRef.current.getZoom()
    mapBoxRef.current.flyTo({
      center: [lng, lat],
      zoom
    })
  }, [mapBoxState, currentPing])
  useEffect(() => {
    mapBoxRef.current = new mapboxgl.Map({
      zoom: mapBoxState.zoom,
      style: "mapbox://styles/mapbox/dark-v10",
      maxZoom: 18,
      minZoom: 1.8,
      container: mapContainer.current,
      dragRotate: false,
      zoomControl: false,
      fadeDuration: 0,
      pitchWithRotate: false,
      renderWorldCopies: true,
      attributionControl: false,
      maxBoundsViscosity: 1.0,
    })
    mapBoxRef.current.dragRotate.disable()

    // disable map rotation using touch rotation gesture
    mapBoxRef.current.touchZoomRotate.disableRotation()

    mapBoxRef.current.on("load", onLoad)
    // mapBoxRef.current.on("moveend", onMapMove)
    // mapBoxRef.current.on("zoomend", onMapMove)

    return () => {
      mapBoxRef.current.off("load", onLoad)
      // mapBoxRef.current.off("moveend", onMapMove)
      // mapBoxRef.current.off("zoomend", onMapMove)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (
      !mapBoxRef.current ||
      isLoading.current ||
      Object.keys(pingAggregates).length === 0 ||
      imagesLeftToLoad
    )
      return
    const clearMap = () => {
      if (!mapBoxRef.current) return
      clearSpiderifiedCluster(mapBoxRef.current)
      mapBoxRef.current.getLayer("clusters") && mapBoxRef.current.removeLayer("clusters")
      mapBoxRef.current.getLayer("cluster-count") && mapBoxRef.current.removeLayer("cluster-count")
      mapBoxRef.current.getLayer("unclustered-point") &&
        mapBoxRef.current.removeLayer("unclustered-point")
      mapBoxRef.current.getSource("pings") && mapBoxRef.current.removeSource("pings")
    }

    const renderMap = pingAggregates => {
      clearMap()
      mapBoxRef.current.addSource("pings", {
        type: "geojson",
        data: pingAggregates,
        cluster: true,
        clusterRadius: 100
      })
      mapBoxRef.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "pings",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          // "#82CF54" Color for less than 10 count cluster
          // "#EED96D" Color for count between 10 and 100
          // in circle-radius, 20 radius for less than 10 count cluster
          "circle-color": ["step", ["get", "point_count"], "#82CF54", 10, "#EED96D", 100, "#E99B53", 1000, "#F1853A"],
          "circle-radius": ["step", ["get", "point_count"], 20, 10, 30, 100, 40, 1000, 50]
        }
      })

      mapBoxRef.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "pings",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 13
        }
      })

      mapBoxRef.current.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "pings",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": ["get", ["string", "iconUrl"]],
          "icon-size": 0.25
        }
      })
      if (showPingOnLoad.current) {
        setCurrentPing(showPingOnLoad.current)
        showPingOnLoad.current = undefined
      }
    }
    renderMap(pingAggregates)
  }, [pingAggregates, imagesLeftToLoad])

  const handlePingCreate = ping => {
    //move all this code into useEffect below for broadcasting of pingleaf 
    const shwMyPngs = document.getElementById('shwMyPngsChkBx');
    if (!userLoggedIn.current && shwMyPngs.checked) {
      // need to ensure to load all pings if the user is not logged-in but show my pings is checked	    
      shwMyPngs.click();
    }
    ReactGA.event({
      category: "Ping Creation",
      action: "Ping Created",
      value: 99
    })

    setCurrentPing(ping);
    setIsNewPing(true)
  }

  useEffect(() => {
    //TODO: get the config key from env
    pusher.current = new Pusher(cfgKeys["PSHER"], {
      cluster: "ap2"
    })
    channelRef.current = pusher.current.subscribe("ping")
    channelRef.current.bind("new", ({ data }) => {
      const newFeature = {
        geometry: {
          type: "Point",
          coordinates: [data.ping.lng, data.ping.lat]
        },
        properties: {
          city: data.ping.city,
          state: data.ping.state,
          title: startCase(data.ping.iconUrl),
          pingts: data.ping.pingts,
          pingid: data.ping.pingid,
          country: data.ping.country,
          iconUrl: data.ping.iconUrl,
          iconSize: [10, 10]
        },
        type: "Feature"
      }

      setPingAggregates(p => {
        return {
          ...p,
          features: [newFeature].concat(p.features || [])
        }
      })

    })
    return () => {
      channelRef.current.unbind("new")
      pusher.current.unsubscribe("ping")
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // load the map with new ping
    if (isNewPing) {
      mapBoxRef.current.getSource("pings").setData(pingAggregates)
      setIsNewPing(false)
    }
  }, [pingAggregates, isNewPing])

  const handleShowMyPings = checked => {
    // load all pings/user's pings
    onMapMove()
    myPings.current = checked
  }

  return (
    <AppWrapper>
      <GlobalStyle />
      <NavBar
        activePings={props.activePings}
        ref={navRef}
        loggedIn={userLoggedIn.current}
        onLogout={() => props.setLoggedIn(false)}
        onPingCreate={handlePingCreate}
        userEmail={userEmaill.current}
        hideLinks
      />
      <Main ref={mapContainer} />
      <SidePanel onShowMyPings={handleShowMyPings} />
      <AnimateSharedLayout>
        {currentPing && (
          <motion.div
            key="leaf"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <RecentPingDisplay onClick={() => setCurrentPing(undefined)}>
              <LeafWrapper>
                <div>
                  <PingCreatedSection>
                    {i18n.t("Labels.pingCreated")}
                    <div>{i18n.t("Labels.approximateLocation")}</div>
                  </PingCreatedSection>
                  <PingLeaf pingInfo={currentPing} />
                </div>
              </LeafWrapper>
            </RecentPingDisplay>
          </motion.div>
        )}
      </AnimateSharedLayout>
    </AppWrapper>
  )
}

Earth.propTypes = propTypes
Earth.defaultProps = defaultProps
Earth.displayName = "Earth"

export default Earth
