/** @format */

export const SPIDER_TYPE = "layer" // marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
export const MAX_LEAVES_TO_SPIDERIFY = 255 // Max leave to display when spiderify to prevent filling the map with leaves
export const CIRCLE_TO_SPIRAL_SWITCHOVER = SPIDER_TYPE.toLowerCase() === "marker" ? 10 : 15 // When below number, will display leave as a circle. Over, as a spiral

export const CIRCLE_OPTIONS = {
  distanceBetweenPoints: 50
}

export const SPIRAL_OPTIONS = {
  rotationsModifier: 1250, // Higher modifier = closer spiral lines
  distanceBetweenPoints: SPIDER_TYPE.toLowerCase() === "marker" ? 42 : 32, // Distance between points in spiral
  radiusModifier: 50000, // Spiral radius
  lengthModifier: 1000 // Spiral length modifier
}

export const SPIDER_LEGS = false
export const SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()
  .toString(36)
  .substr(2, 9)}`
export const SPIDER_LEGS_PAINT_OPTION = {
  "line-width": 1,
  "line-color": "rgba(128, 128, 128, 0.5)"
}
export const SPIDER_LEAVES_LAYER_NAME_PREFIX = "spider-leaves"
export const SPIDER_LEAVES_LAYER_NAME = `${SPIDER_LEAVES_LAYER_NAME_PREFIX}-${Math.random()
  .toString(36)
  .substr(2, 9)}`
